<template>
  <div class="blurry-text-card">
    <div class="inside-container">
      <div class="title-container">
        <h4>{{ title }}</h4>
        <p v-if="subtitle" class="subtitle">{{ subtitle }}</p>
      </div>
      <div class="blurry-card-separator"></div>
      <p>{{ text }}</p>
      <p v-if="text2">{{ text2 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextCard',

  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      default: '',
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    text2: {
      default: '',
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.blurry-text-card {
  width: 340px;
  border-radius: 10px;
  text-align: left;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  .inside-container {
    border: 1px solid #eb6c34;
    border-radius: 10px;
    height: 100%;
    .title-container {
      height: 80px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      h4 {
        font-size: 32px;
        margin: 0 0 10px 0;
        margin: 20px 0 0 10px;
        width: 250px;
      }

      .subtitle {
        color: #eb6c34;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .blurry-card-separator {
      border-top: 2px solid white;
      margin: 0 10px;
    }

    p {
      font-size: 18px;
      width: 290px;
      margin: 10px 0 30px 10px;
    }
  }
}

@media screen and (max-device-width: 790px) {
  .blurry-text-card {
    width: 300px;
    .inside-container {
      .title-container {
        // height: 80px;
        // margin-bottom: 20px;
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        h4 {
          font-size: 25px;
          line-height: 25px;
          width: 200px;
        }

        .subtitle {
          // color: #eb6c34;
          // font-weight: 600;
          // margin-top: 0;
          // margin-bottom: 0;
        }
      }

      p {
        font-size: 14px;
        width: 200px;
      }
    }
  }
}
</style>
