import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Homepage from '../views/Homepage.vue';
import AgencyPage from '../views/AgencyPage.vue';
import AtelierPage from '../views/AtelierPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Homepage,
  },
  {
    path: '/agence',
    name: 'agency',
    component: AgencyPage,
  },
  {
    path: '/atelier',
    name: 'atelier',
    component: AtelierPage,
  },
  {
    path: '/#team',
    name: 'contact',
    component: Homepage,
  },
];

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
