<template>
  <div class="image-card">
    <div class="container">
      <img :src="imgPath" alt="vitrophanie" />
      <h2>Film<br />{{ title }}</h2>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    imgName: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      imgPath: require(`../assets/vitrophanie/${this.imgName}`),
    };
  },
};
</script>

<style lang="scss" scoped>
.image-card {
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  width: 290px;
  height: 360px;
  .container {
    border: 1px solid #eb6c34;
    border-radius: 10px;
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    h2 {
      margin: 10px 0 5px 0;
      font-family: 'Bebas Neue';
      font-size: 55px;
      line-height: 50px;
    }

    p {
      margin: 0 0 10px 0;
    }
  }
}

@media screen and (max-device-width: 790px) {
  .image-card {
    height: 300px;
    .container {
      height: 280px;

      h2 {
        font-size: 35px;
        line-height: 35px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
</style>
