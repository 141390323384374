<template>
  <CustomHeader />
  <img
    src="./assets/side-leaves-right.png"
    alt="leaves decoration"
    class="side-leaves-right"
  />
  <img
    src="./assets/side-leaves-left.png"
    alt="leaves decoration"
    class="side-leaves-left"
  />
  <router-view />
  <CustomFooter />
</template>

<script>
import CustomHeader from './components/CustomHeader.vue';
import CustomFooter from './components/CustomFooter.vue';

export default {
  name: 'App',
  components: { CustomHeader, CustomFooter },
  computed: {
    isMobile() {
      return window.innerWidth <= 790;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app {
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
body {
  margin: 0;
  overflow-x: hidden;
  background: url('./assets/background.png');
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}
html {
  background-color: black;
  position: relative;
  z-index: 4;
}
.side-leaves-right {
  position: fixed;
  right: 0;
  width: 20%;
  z-index: 2;
}

.side-leaves-left {
  position: fixed;
  left: 0;
  width: 20%;
  z-index: 2;
}

@media screen and (max-device-width: 790px) {
  body:before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0n;
    background: url('./assets/background-mobile.png') no-repeat;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
  html {
    overflow-x: hidden;
  }
  .side-leaves-left {
    width: 80px;
    z-index: 2;
  }
  .side-leaves-right {
    width: 80px;
    z-index: 2;
  }

  .burger-menu {
    position: relative;
    z-index: 15;
  }
}
</style>
