<template>
  <div class="horizontal-card">
    <div class="content-container">
      <img v-if="pictoName" alt="picto" :src="loadImg" class="picto" />
      <div class="content">
        <div class="title-container">
          <p class="number">{{ number }}</p>
          <h4>{{ title }}</h4>
        </div>
        <p class="subtitle">{{ subtitle }}</p>
        <p>{{ text1 }}</p>
        <p v-if="text2">{{ text2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorizontalCard',
  props: {
    pictoName: {
      default: '',
      type: String,
    },
    text1: {
      required: true,
      type: String,
    },
    text2: {
      default: '',
      type: String,
    },
    number: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      default: '',
      type: String,
    },
  },
  computed: {
    loadImg() {
      return this.pictoName
        // eslint-disable-next-line import/no-dynamic-require, global-require
        ? require(`../assets/pictos/${this.pictoName}`)
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal-card {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  width: 520px;
  padding: 10px;
  border-radius: 10px;

  .content-container {
    border: 1px solid #eb6c34;
    border-radius: 10px;
    padding: 20px 10px 20px 10px;
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 15px;
    .picto {
      height: 60px;
      margin: 0 20px 0 10px;
    }

    .content {
      border-left: 1px solid white;
      .title-container {
        display: flex;
        align-items: center;
        font-family: 'Bebas Neue';
        font-size: 40px;
        margin: 0 0 0 20px;
        line-height: 35px;
        .number {
          color: #eb6c34;
          font-size: 50px;
          margin: 0 5px 0 0;
        }

        h4 {
          margin: 0;
        }
      }

      p {
        margin-left: 20px;
      }
      .subtitle {
        font-weight: 600;
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-device-width: 790px) {
  .horizontal-card {
  width: 320px;

  .content-container {
    .picto {
      height: 50px;
      margin: 0 10px 0 5px;
    }

    .content {
      .title-container {
        font-size: 28px;
        line-height: 25px;
        .number {
          font-size: 32px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }
}
}
</style>
