<template>
  <div class="atelier-page">
    <div class="page-start">
      <h2>L'atelier</h2>
      <p>Soyez visible et démarquez vous des autres.</p>
      <p class="subtitle">Nous sommes à votre écoute !</p>
    </div>
    <div class="blurry-section">
      <div class="covering-title">
        <h3 id="flocage-covering">Flocage<br />et covering</h3>
        <img src="../assets/covering-flocage.png" alt="decorative-cars" />
      </div>
      <div class="covering-intro">
        <p>
          Dans un univers concurrentiel c’est souvent la première impression qui
          compte.
        </p>
        <p>Gagnez en visibilité.</p>
        <p>
          Les véhicules sont un formidable outil de communication, les
          nombreuses heures passées au volant offrent un avantage conséquent en
          termes d’exposition, services proposés, produits et nouveautés.
        </p>
        <h4>Des formats élégants et impactants !</h4>
      </div>
      <div class="covering-examples">
        <div v-for="(item, i) in coveringCars" v-bind="item" :key="i">
          <img :src="item.imgPath" alt="covering car" />
          <p class="centered-text">{{ item.text }}</p>
        </div>
      </div>
      <div class="covering-examples">
        <div v-for="(item, i) in coveringUtilitaires" v-bind="item" :key="i">
          <img :src="item.imgPath" alt="covering utilitaires" />
          <p class="centered-text">{{ item.text }}</p>
        </div>
      </div>
      <div class="covering-cards">
        <p class="vertical-text">Nos offres !</p>
        <BlurryTextCard
          v-for="(item, i) in coveringCards"
          v-bind="item"
          :key="i"
        />
      </div>
      <div class="covering-details">
        <h4>Nos produits</h4>
        <p>
          DINO vous propose une large palette de coloris et finitions haut de
          gamme pour personnaliser votre voiture :
        </p>
        <p>
          Plus de 100 couleurs !<br />
          De multiples finitions originales : métal brossé, métallisé, fibre de
          carbone, holographique, fluo, ...
        </p>
        <p>
          Nous travaillons avec nos nombreux fournisseurs pour répondre à toutes
          vos attentes, HEXIS, APA, 3M, ...
        </p>
      </div>
      <h3 id="habillage-interieur">Habillage<br />d'intérieur</h3>
      <div class="interior-decoration-introduction">
        <p class="subtitle">
          <b>La touche finale pour une décoration tendance et abordable.</b>
        </p>
        <p>
          Transformez votre espace intérieur en un clin d’œil avec notre film
          adhésif design. Économique et facile à utiliser, notre solution vous
          permet de créer une ambiance unique sans les tracas des travaux
          coûteux. Réinventez votre intérieur dès maintenant !
        </p>
        <p>
          <b>
            Un matériau de pointe qui offre l’aspect et le toucher de textures
            réaliste, un film rigide à haute résistance.
          </b>
        </p>
      </div>
      <div class="labeled-images">
        <LabeledImage imgPath="/atelier-pictures/decoration-murale-avant.png" />
        <LabeledImage
          imgPath="/atelier-pictures/decoration-murale-apres.png"
          isAfter
        />
        <img
          alt=""
          src="@/assets/pose-decoration.png"
          class="wide-img"
          v-if="!isMobile"
        />
      </div>
      <h4>Nos matières</h4>
      <div class="fabrics-circles">
        <div v-for="(item, i) in fabricCircles" :key="i">
          <img :src="item.imgPath" :alt="item.text" />
          <p>{{ item.text }}</p>
        </div>
      </div>
      <div class="fabrics-texts">
        <p class="centered-text">
          <i>
            Couleurs Unies • Bois • Métallisé • Cuir • Marbre • Pierre • Brique
            • Rouille • Soft touch
          </i>
        </p>
        <p>
          <b>1 - Installation rapide et facile.</b><br />
          Grâce à notre technologie de revêtement adhésif, l’application de nos
          matériaux est facile et rapide, avec un rendu sans bulles, une
          résistance à la chaleur et à l’humidité
        </p>
        <p>
          <b>2 - Rentabilité optimale pour vos projets</b>, moins couteux qu’une
          rénovation traditionnelle.
        </p>
        <p>
          <b>3 - Esthétique haut de gamme.</b><br />Nous utilisons des matériaux
          de pointe pour créer des surfaces au rendu réaliste, avec un toucher
          haut de gamme, vous permettant de bénéficier d’une esthétique de
          qualité supérieure.
        </p>
        <p><b>4 - Installation sans poussière et silencieuse.</b></p>
        <p>
          <b>5 - Entretien facile.</b><br />Notre revêtement est facile à
          entretenir vous permettant de conserver l’aspect neuf de votre
          surface.
        </p>
      </div>
      <div class="interior-design-cards">
        <div class="interior-design-card-left" v-if="!isMobile">
          <HorizontalCard
            v-for="(item, i) in interiorDecorationCardsLeft"
            v-bind="item"
            :key="i"
            class="interior-design-elem"
          />
        </div>
        <div class="interior-design-card-right" v-if="!isMobile">
          <HorizontalCard
            v-for="(item, i) in interiorDecorationCardsRight"
            v-bind="item"
            :key="i"
            class="interior-design-elem"
          />
        </div>
        <div v-if="isMobile">
          <HorizontalCard
            v-for="(item, i) in interiorDecorationCardsMobile"
            v-bind="item"
            :key="i"
            class="interior-design-elem"
          />
        </div>
      </div>
      <h3>Vitrages<br />et vitrines</h3>
      <div class="vitrine-introduction">
        <p>
          <b
            >C’est le support idéal pour communiquer de manière originale et
            personnalisée, sur le long terme comme sur le court terme.</b
          >
        </p>
      </div>
      <div class="labeled-images">
        <LabeledImage imgPath="/atelier-pictures/vitrophanie-avant.png" />
        <LabeledImage
          imgPath="/atelier-pictures/vitrophanie-apres.png"
          isAfter
        />
      </div>
      <div class="vitrine-cards">
        <ImageCard
          v-for="(item, i) in vitrineCards"
          v-bind="item"
          :key="i"
          class="vitrine-elem"
        />
      </div>
      <div class="vitrine-details">
        <p class="side-separator">
          <b>
            La vitrophanie permet de communiquer efficacement sur des offres
            promotionnelles, de la publicité, ou encore des nouveaux produits.
          </b>
        </p>
        <p>
          Elle offre également de nombreux autres avantages, pour la santé,
          sécurité et peut s’avérer utile pour réduire vos coûts énergétiques.
        </p>
      </div>

      <div class="advertising-intro">
        <h3 id="produits-publicitaires">Produits publicitaires</h3>
        <p>
          Nos produits publicitaires sont conçus pour maximiser votre visibilité
          auprès de votre audience cible, en utilisant des stratégies de
          marketing innovantes et des supports de communication efficaces, pour
          vous aider à atteindre vos objectifs de croissance et de rentabilité.
        </p>
      </div>
      <div class="advertising-cards">
        <ProductCard
          v-for="(item, i) in productCards"
          v-bind="item"
          :key="i"
          class="advertising-elem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LabeledImage from '../components/LabeledImage.vue';
import HorizontalCard from '../components/HorizontalCard.vue';
import ImageCard from '../components/ImageCard.vue';
import ProductCard from '../components/ProductCard.vue';
import BlurryTextCard from '../components/BlurryTextCard.vue';

export default {
  name: 'AtelierPage',
  components: {
    LabeledImage,
    HorizontalCard,
    ImageCard,
    ProductCard,
    BlurryTextCard,
  },

  methods: {
    loadImg(imgPath) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`../assets/${imgPath}`);
    },
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 790;
    },
  },

  mounted() {
    setTimeout(() => {
      const { id } = this.$route.query;
      if (id) {
        const el = document.getElementById(id);
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });
  },

  data() {
    return {
      interiorDecorationCardsLeft: [
        {
          text1:
            "Durabilité, conformabilité, type d'adhésif, exposition interne ou externe.",
          number: '01',
          title: "Choissisez votre film d'impression",
        },
        {
          text1:
            'Faites votre sélection parmis nos gammes de lamination. Certains élements nécéssitent une protection importante.',
          number: '03',
          title: 'Lamination à effets et antidérapante',
        },
      ],
      interiorDecorationCardsRight: [
        {
          text1:
            'Personalisez vos travaux et insérez sur votre revêtement votre message ou image.',
          number: '02',
          title: 'Création du design',
        },
        {
          text1:
            "Rendu sans bulle, une résitance à la chaleur et à l'humidité. Sublimez vos espaces intérieurs !",
          number: '04',
          title: 'Pose',
        },
      ],
      interiorDecorationCardsMobile: [
        {
          text1:
            "Durabilité, conformabilité, type d'adhésif, exposition interne ou externe.",
          number: '01',
          title: "Choissisez votre film d'impression",
        },
        {
          text1:
            'Personalisez vos travaux et insérez sur votre revêtement votre message ou image.',
          number: '02',
          title: 'Création du design',
        },
        {
          text1:
            'Faites votre sélection parmis nos gammes de lamination. Certains élements nécéssitent une protection importante.',
          number: '03',
          title: 'Lamination à effets et antidérapante',
        },
        {
          text1:
            "Rendu sans bulle, une résitance à la chaleur et à l'humidité. Sublimez vos espaces intérieurs !",
          number: '04',
          title: 'Pose',
        },
      ],
      vitrineCards: [
        {
          title: 'découpe',
          text: 'Découpe à la forme de votre choix, logo, lettrage, pour une communication personnalisée.',
          imgName: 'Vitrophanie-1.png',
        },
        {
          title: 'imprimé',
          text: 'Habillez et personnalisez les vitrages et parois vitrées grâce à de larges gammes de couleurs et de motifs.',
          imgName: 'Vitrophanie-2.png',
        },
        {
          title: 'microperf',
          text: 'Opacifie la lumière tout en laissant passer la lumière naturelle.',
          imgName: 'Vitrophanie-3.png',
        },
        {
          title: 'miroir',
          text: 'Occulte la vision de l’extérieur tout en conservant la vision de l’intérieur.',
          imgName: 'Vitrophanie-4.png',
        },
        {
          title: 'sablé',
          text: 'Opacifie la lumière tout en laissant passer la lumière naturelle.',
          imgName: 'Vitrophanie-5.png',
        },
        {
          title: 'anti-graffiti',
          text: 'Résistance aux tags et graffitis, absorbe les rayures.',
          imgName: 'Vitrophanie-6.png',
        },
      ],
      productCards: [
        {
          title: 'Carte de visite',
          imgName: 'Carte-de-visite.png',
        },
        {
          title: 'Flyers',
          imgName: 'Flyer.png',
        },
        {
          title: 'Depliant et brochure',
          imgName: 'Depliant.png',
        },
        {
          title: 'Affiches',
          imgName: 'Affiche.png',
        },
        {
          title: 'Bâche',
          imgName: 'Bache.png',
        },
        {
          title: 'Enseigne et panneau',
          imgName: 'Enseigne.png',
        },
        {
          title: 'Roll-up',
          imgName: 'Roll-Up.png',
        },
        {
          title: 'Totem',
          imgName: 'Totem.png',
        },
        {
          title: 'Drapeau',
          imgName: 'Drapeau.png',
        },
        {
          title: 'Textile',
          imgName: 'T-Shirts.png',
        },
        {
          title: 'Casquette',
          imgName: 'Casquette.png',
        },
        {
          title: 'Mug',
          imgName: 'Mug.png',
        },
      ],
      coveringCards: [
        {
          title: 'Comfort',
          subtitle: 'Flocage et/ou Semi-Covering',
          text: 'Notre agence recueille les éléments nécessaires au graphisme souhaité et les adapte pour l’impression.',
          text2:
            'Nous assurons la pose pour vous garantir la plus longue durée de vie. 4 jours de délais pour cette offre où nous comprenons 1 à 2 journées d’immobilisation du véhicule selon le format du covering choisi, simple ou semi.',
        },
        {
          title: 'Premium',
          subtitle: 'Flocage et/ou Semi-Covering',
          text: 'Distinguez-vous, prenez conscience de l’importance de l’image que vous véhiculez. C’est en ce sens que nous travaillons à comprendre votre entreprise, son modèle et surtout votre cible.',
          text2:
            'Notre bureau d’étude et notre atelier travaillent conjoitement, avec toutes leurs exigeances pour créer votre visuel et faire de ce marquage publicitaire une réussite dans le développement de votre activité.',
        },
        {
          title: 'All-in',
          subtitle: 'Total-Covering',
          text: 'Optez pour le total covering, vous allez pouvoir personnaliser votre véhicule et exprimer votre personnalité. Votre voiture sera unique et à votre image. L’expérience de notre équipe vous permettra de trouver le bon équilibre entre votre budget et votre exigeance.',
          text2:
            'Fini les carrosseries classiques et passe partout, DINO vous présente une alternative 100% personnalisable et sur mesure pour votre voiture.',
        },
      ],
      coveringCars: [
        {
          imgPath: this.loadImg('covering/covering-car-flocage.png'),
        },
        {
          imgPath: this.loadImg('covering/covering-car-semi.png'),
        },
        {
          imgPath: this.loadImg('covering/covering-car-complete.png'),
        },
      ],
      coveringUtilitaires: [
        {
          imgPath: this.loadImg('covering/covering-utilitaire-flocage.png'),
        },
        {
          imgPath: this.loadImg('covering/covering-utilitaire-semi.png'),
        },
        {
          imgPath: this.loadImg('covering/covering-utilitaire-complete.png'),
        },
      ],
      fabricCircles: [
        {
          imgPath: this.loadImg('fabrics/couleur.png'),
          text: 'Couleurs unies',
        },
        {
          imgPath: this.loadImg('fabrics/bois.png'),
          text: 'Bois',
        },
        {
          imgPath: this.loadImg('fabrics/cuir.png'),
          text: 'Cuir',
        },
        {
          imgPath: this.loadImg('fabrics/metal.png'),
          text: 'Métalisé',
        },
        {
          imgPath: this.loadImg('fabrics/marbre.png'),
          text: 'Marbre',
        },
        {
          imgPath: this.loadImg('fabrics/tissus.png'),
          text: 'Tissus',
        },
        {
          imgPath: this.loadImg('fabrics/pierre.png'),
          text: 'Pierre',
        },
        {
          imgPath: this.loadImg('fabrics/rouille.png'),
          text: 'Rouille',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.atelier-page {
  padding-top: 130px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-bottom: 135px;
    h2 {
      font-size: 380px;
      font-family: 'Bebas Neue';
      margin: 150px 0 40px 0;
      letter-spacing: -5px;
      line-height: 180px;
    }
    p {
      font-size: 28px;
      width: 1030px;
      margin: 0;
    }
    .subtitle {
      font-weight: 600;
      margin-bottom: 6%;
    }
  }
  .blurry-section {
    backdrop-filter: blur(8px);
    border: 3px solid #a3aaa621;
    border-radius: 10px;
    margin: 0 100px;
    padding: 70px 100px 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
    padding-bottom: 200px;
    max-width: 1200px;
    .side-separator {
      border-left: 2px solid #eb6c34;
    }
    h3 {
      align-self: flex-start;
      font-family: 'Bebas Neue';
      font-size: 148px;
      margin: 0;
      line-height: 130px;
      text-align: left;
    }
    h4 {
      align-self: flex-start;
      font-family: 'Bebas Neue';
      font-size: 50px;
      text-align: left;
      margin-bottom: 20px;
    }
    p {
      text-align: left;
      align-self: flex-start;
      font-size: 20px;
      margin: 0;
    }
    .interior-decoration-introduction {
      max-width: 1000px;
      align-self: flex-start;
      .subtitle {
        margin-bottom: 20px;
      }
    }
    .labeled-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 40px 0 0 0;
      width: 1000px;
      row-gap: 20px;
      .wide-img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .fabrics-circles {
      display: flex;
      justify-content: center;
      width: 1000px;
      column-gap: 65px;
      row-gap: 30px;
      flex-wrap: wrap;
      margin-top: 20px;

      img {
        width: 180px;
        height: 180px;
        border-radius: 100px;
      }

      p {
        margin: 10px 0 0 0;
        text-align: center;
        font-weight: 600;
        font-size: 22px;
      }
    }
    .fabrics-texts {
      margin-bottom: 80px;
      .centered-text {
        text-align: center;
        margin-bottom: 40px;
      }
      p {
        margin: 20px 0;
      }
    }

    .interior-design-cards {
      display: flex;
      row-gap: 10px;
      margin-bottom: 60px;

      .interior-design-elem {
        margin-bottom: 10px;
      }
      .interior-design-card-left {
        margin: 0 10px 10px 0;
      }

      .interior-design-card-right {
        margin-top: 120px;
      }
    }

    .interior-design-details {
      margin: 60px 0 100px 0;
      width: 1000px;
      align-self: flex-start;
      p {
        margin-bottom: 20px;
        font-weight: 500;
        padding-left: 10px;
      }
    }

    .vitrine-introduction {
      max-width: 1000px;
      align-self: flex-start;
    }

    .vitrine-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 1000px;
      margin-top: 60px;

      .vitrine-elem {
        margin-bottom: 20px;
      }
    }

    .vitrine-details {
      margin: 30px 0 90px 0;
      width: 1000px;
      p {
        margin-bottom: 30px;
        padding-left: 10px;
      }
    }

    .covering-title {
      display: flex;
      width: 100%;

      img {
        width: 400px;
        height: 250px;
        margin-left: 55px;
      }
    }

    .covering-intro {
      width: 1000px;
      align-self: flex-start;
      p {
        margin-bottom: 20px;
      }
    }

    .covering-examples {
      display: flex;

      .centered-text {
        text-align: center;
        margin: 10px 0 30px 0;
      }
      img {
        width: 300px;
      }
    }

    .covering-cards {
      display: flex;
      justify-content: space-between;
      width: 1100px;
      margin-top: 50px;
      position: relative;

      .vertical-text {
        font-family: 'Bebas Neue';
        font-size: 60px;
        position: absolute;
        transform: rotate(270deg);
        left: -150px;
        top: 190px;
      }
    }

    .covering-details {
      width: 1000px;
      align-self: flex-start;
      margin-bottom: 100px;
      p {
        margin-bottom: 20px;
      }
    }

    .advertising-intro {
      align-self: flex-start;
      width: 1000px;
    }

    .advertising-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 50px 0;

      .advertising-elem {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-device-width: 790px) {
  .atelier-page {
    padding-top: 85px;
    z-index: 3;
    position: relative;
    .page-start {
      margin-bottom: 5px;
      padding: 10px;
      h2 {
        font-size: 100px;
        margin: 0;
        line-height: 70px;
        letter-spacing: 0px;
      }
      p {
        font-size: 14px;
        width: unset;
        margin: 0 10px 0 35px;
      }
      .subtitle {
        align-self: flex-start;
      }
    }
    .blurry-section {
      max-width: unset;
      position: relative;
      margin: 0;
      padding: 40px 0 0 0;
      width: 99%;
      h3 {
        font-size: 60px;
        margin: 0;
        margin-left: 30px;
        line-height: 55px;
      }
      h4 {
        font-size: 60px;
        line-height: 55px;
        margin-left: 30px;
      }
      p {
        font-size: 14px;
        margin: 0 30px;
      }
      .interior-decoration-introduction {
        max-width: unset;
      }
      .labeled-images {
        row-gap: 15px;
        column-gap: 15px;
        width: unset;
      }

      .fabrics-circles {
        width: unset;
        column-gap: 40px;
        row-gap: 30px;

        img {
          width: 120px;
          height: 120px;
          border-radius: 100px;
        }

        p {
          text-align: center;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .fabrics-texts {
        margin-bottom: 40px;
        padding: 0 40px;
        p {
          margin: 20px 0;
        }
      }

      .interior-design-details {
        margin-bottom: 40px;
        width: unset;
      }

      .vitrine-introduction {
        max-width: unset;
      }

      .vitrine-cards {
        justify-content: center;
        width: unset;
      }

      .vitrine-details {
        width: unset;
      }

      .covering-title {
        width: unset;
        height: unset;
        position: relative;
        align-self: flex-start;
        margin-left: 30px;
        h3 {
          width: 300px;
          margin: 0;
        }
        img {
          position: absolute;
          width: 200px;
          object-fit: contain;
          margin-left: 0;
          right: -80px;
          top: -105px;
        }
      }

      .covering-intro {
        width: unset;
      }

      .covering-examples {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;
        .centered-text {
          margin: 10px 0 0 0;
        }
        img {
          width: 180px;
        }
      }

      .covering-cards {
        margin-top: 90px;
        width: unset;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
        column-gap: 10px;

        .vertical-text {
          font-size: 50px;
          transform: unset;
          left: 0;
          top: -70px;
        }
      }

      .covering-details {
        width: unset;
        margin-bottom: 50px;
      }

      .advertising-intro {
        width: unset;
      }

      .advertising-cards {
        margin: 50px 0 150px 0;
      }
    }
  }
}
</style>
