<template>
  <div class="arrow"></div>
</template>

<style lang="scss" scoped>
.arrow {
  display: block;
  margin: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  transform: rotate(135deg);
}

.arrow::after {
  content: '';
  display: block;
  width: 2px;
  height: 45px;
  background-color: white;
  transform: rotate(-45deg) translate(15px, 4px);
  left: 0;
  top: 0;
}

@media screen and (max-device-width: 790px) {
  .arrow {
    width: 7px;
    height: 7px;
  }
}
</style>
