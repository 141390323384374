<template>
  <router-link class="vertical-card" :to="toLink">
    <div class="inner-container">
      <div class="title-image" :style="cssVars">
        <h2>{{ title }}</h2>
      </div>
      <div class="card-text">
        <h3>{{ subTitle }}</h3>
        <p>
          {{ textContent }}
        </p>
      </div>
      <AnimatedArrow class="arrow-position" />
    </div>
  </router-link>
</template>

<script>
import AnimatedArrow from './AnimatedArrow.vue';

export default {
  name: 'VerticalCard',
  components: { AnimatedArrow },
  props: {
    title: {
      required: true,
      type: String,
    },
    subTitle: {
      required: true,
      type: String,
    },
    textContent: {
      required: true,
      type: String,
    },
    imgPath: {
      required: true,
      type: String,
    },
    toLink: {
      required: true,
      type: String,
    },
  },

  computed: {
    loadImg() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../assets${this.imgPath}`);
    },
    cssVars() {
      return {
        '--background-image': `url(${this.loadImg})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.vertical-card {
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
  color: white;
  .inner-container {
    border: 1px solid #eb6c34;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .title-image {
      align-self: center;
      width: 270px;
      margin-top: 10px;
      height: 270px;
      border-radius: 10px;
      box-shadow: -6px 8px 15px 0px rgba(0, 0, 0, 0.37);
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 49%,
          rgba(0, 0, 0, 0.65) 100%
        ),
        var(--background-image);
      background-size: cover;
      display: flex;
      align-items: flex-end;
      h2 {
        margin: 0 0 12px 12px;
        font-family: 'Bebas Neue';
        font-size: 55px;
        line-height: 50px;
      }
    }

    .card-text {
      margin: 0 15px 10px 15px;
    }

    .arrow-position {
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  }
}

@media screen and (max-device-width: 790px) {
  .vertical-card {
    width: 145px;
    .inner-container {
      .title-image {
        width: 120px;
        height: 120px;
        h2 {
          margin: 0 0 4px 4px;
          font-size: 20px;
          line-height: 20px;
        }
      }

      .card-text {
        h3 {
          font-size: 16px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
