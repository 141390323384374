<template>
  <div class="agency">
    <div class="page-start">
      <h2>L'agence</h2>
      <p v-if="!isMobile">
        Une entreprise c’est avant tout une somme de relations humaines,
      </p>
      <p v-if="!isMobile" class="subtitle">
        DINO Communication est guidé par ses valeurs, l’innovation, la passion
        et la durabilité.
      </p>
      <p v-if="isMobile" class="subtitle">
        Une entreprise c’est avant tout une somme de relations humaines, DINO
        Communication est guidé par ses valeurs, l’innovation, la passion et la
        durabilité.
      </p>
    </div>
    <div class="blurry-section">
      <h4>Nos<br />engagements</h4>
      <div class="engagements-section">
        <BlurryTextCard
          v-for="(item, i) in engagementsCards"
          v-bind="item"
          :key="i"
        />
      </div>
      <div class="graphic-creation" id="studio-graphique">
        <h2>Exprimez<br />vos idées en création<br />graphique</h2>
        <p>Rendez visible votre identité et démarquez vous des autres</p>
        <p class="subtitle">Nous sommes à votre écoute !</p>
        <div class="graphic-creation-cards">
          <div class="left" v-if="!isMobile">
            <HorizontalCard
              v-for="(item, i) in horizontalCardsLeft"
              v-bind="item"
              :key="i"
              class="graphic-creation-card-spacing"
            />
          </div>
          <div class="right" v-if="!isMobile">
            <HorizontalCard
              v-for="(item, i) in horizontalCardsRight"
              v-bind="item"
              :key="i"
              class="graphic-creation-card-spacing"
            />
          </div>
          <div v-if="isMobile">
            <HorizontalCard
              v-for="(item, i) in horizontalCardsMobile"
              v-bind="item"
              :key="i"
              class="graphic-creation-card-spacing"
            />
          </div>
        </div>
      </div>
      <p class="end-text">
        Prenez le contrôle de votre marque, développez votre communauté et
        offrez une expérience unique !
      </p>
    </div>
  </div>
</template>

<script>
import BlurryTextCard from '../components/BlurryTextCard.vue';
import HorizontalCard from '../components/HorizontalCard.vue';

export default {
  name: 'AgencyPage',
  components: { BlurryTextCard, HorizontalCard },

  computed: {
    isMobile() {
      return window.innerWidth <= 790;
    },
  },

  mounted() {
    setTimeout(() => {
      const { id } = this.$route.query;
      if (id) {
        const el = document.getElementById(id);
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });
  },

  data() {
    return {
      engagementsCards: [
        {
          title: 'Innovation et excellence',
          text: 'Nous faisons de l’innovation une priorité et travaillons sur des produits durables pour vous garantir le meilleur résutat dans la durée.',
        },
        {
          title: 'Passion et achèvement',
          text: 'Nous avons la responsabilité d’avoir un impact positif, nous sommes là pour vous conseiller et guider vers la solution la mieux adaptée à vos besoins.',
        },
        {
          title: 'Durabilité',
          text: 'Bâtissons ensemble une relation de confiance. Nous garantissons la durabilités de nos produits et vous assurons suivi et accompagnement.',
        },
      ],
      websiteCards: [
        {
          title: 'Audit et conseil web',
          text: 'Auditer, vous écouter, définir votre stratégie digitale, vous conseiller',
        },
        {
          title: 'UX Design',
          text: 'Définir l’ergonomie de votre plateforme, optimiser l’expérience utilisateur',
        },
        {
          title: 'Création graphique',
          text: 'Réaliser votre charte graphique, votre identité visuelle, votre logo, votre design',
        },
        {
          title: 'Développement',
          text: 'Créer et développer votre site web',
        },
        {
          title: 'Référencement SEO',
          text: 'Être visible sur Google, atirer de nouveaux clients, développer vos activités',
        },
        {
          title: 'Rédaction Contenus Web',
          text: 'Concevoir des messages clairs, optimiser vos textes pour le SEO',
        },
      ],
      horizontalCardsLeft: [
        {
          pictoName: 'search.png',
          text1:
            'Créer le lien pertinent entre la stratégie de développement souhaité et les nombreux outils à notre disposition.',
          text2:
            ' Les supports sur lesquels notre agence est capable de travailler sont nombreux : vitrines, véhicules, signalétiques, décorations murales, digital, documents imprimés, PLV.',
          number: '02',
          title: 'Recherche',
          subtitle:
            'Sans relâche chercher des idées, être alerte des tendances, établir la stratégie qui vous correspond. ',
        },
        {
          pictoName: 'rocket.png',
          text1:
            ' La combinaison studio graphique et atelier de production vous assure un accompagnement pérenne, un gain important de temps et des économies d’argent.',
          subtitle:
            'Faites de votre nouvel outil un atout pour votre entreprise',
          number: '04',
          title: 'Deploiement',
        },
      ],
      horizontalCardsRight: [
        {
          pictoName: 'talk.png',
          text1:
            'Parvenir à transmettre aux clients l’ADN de votre entreprise est le résultat d’un assemblage complexe. L’image que nous renvoyons est primordiale dans la confiance qui nous est accordée.',
          text2:
            ' L’histoire de la société, votre périmètre d’activité, vos avantages concurrentiels sont autant d’éléments nécessaires pour créer des visuels percutants.',
          number: '01',
          title: 'brief',
          subtitle:
            'Partagez nous votre univers, vos idées, la vision que avez de votre projet',
        },
        {
          pictoName: 'brush.png',
          text1:
            'L’assemblage de vos idées, de vos éléments qui font votre identité, suit une mécanique précise autour d’une structuration précise.',
          subtitle: 'Construire et donner de l’existence à votre projet',
          number: '03',
          title: 'creation',
        },
      ],
      horizontalCardsMobile: [
        {
          pictoName: 'talk.png',
          text1:
            'Parvenir à transmettre aux clients l’ADN de votre entreprise est le résultat d’un assemblage complexe. L’image que nous renvoyons est primordiale dans la confiance qui nous est accordée.',
          text2:
            ' L’histoire de la société, votre périmètre d’activité, vos avantages concurrentiels sont autant d’éléments nécessaires pour créer des visuels percutants.',
          number: '01',
          title: 'brief',
          subtitle:
            'Partagez nous votre univers, vos idées, la vision que avez de votre projet',
        },
        {
          pictoName: 'search.png',
          text1:
            'Créer le lien pertinent entre la stratégie de développement souhaité et les nombreux outils à notre disposition.',
          text2:
            ' Les supports sur lesquels notre agence est capable de travailler sont nombreux : vitrines, véhicules, signalétiques, décorations murales, digital, documents imprimés, PLV.',
          number: '02',
          title: 'Recherche',
          subtitle:
            'Sans relâche chercher des idées, être alerte des tendances, établir la stratégie qui vous correspond. ',
        },
        {
          pictoName: 'brush.png',
          text1:
            'L’assemblage de vos idées, de vos éléments qui font votre identité, suit une mécanique précise autour d’une structuration précise.',
          subtitle: 'Construire et donner de l’existence à votre projet',
          number: '03',
          title: 'creation',
        },
        {
          pictoName: 'rocket.png',
          text1:
            ' La combinaison studio graphique et atelier de production vous assure un accompagnement pérenne, un gain important de temps et des économies d’argent.',
          subtitle:
            'Faites de votre nouvel outil un atout pour votre entreprise',
          number: '04',
          title: 'Deploiement',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.agency {
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .page-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-bottom: 100px;
    h2 {
      font-size: 380px;
      font-family: 'Bebas Neue';
      margin: 150px 0 40px 0;
      letter-spacing: -5px;
      line-height: 180px;
    }
    p {
      font-size: 28px;
      width: 1030px;
      margin: 0;
    }
    .subtitle {
      font-weight: 600;
      margin-bottom: 6%;
    }
  }
  .blurry-section {
    max-width: 1200px;
    backdrop-filter: blur(8px);
    border: 3px solid #a3aaa621;
    border-radius: 10px;
    margin: 0 100px;
    padding: 70px 100px 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 4;
    h4 {
      align-self: flex-start;
      font-family: 'Bebas Neue';
      font-size: 148px;
      margin: 0;
      line-height: 130px;
      text-align: left;
    }

    .engagements-section {
      display: flex;
      justify-content: space-between;
      width: 1100px;
      margin-top: 60px;
    }

    .graphic-creation {
      margin-top: 100px;
      width: 100%;
      text-align: left;
      position: relative;
      h2 {
        font-family: 'Bebas Neue';
        font-size: 148px;
        margin: 0;
        line-height: 130px;
      }
      p {
        margin: 0;
      }
      .subtitle {
        color: #eb6c34;
        font-weight: 700;
        font-size: 25px;
        margin-top: 5px;
      }

      .graphic-creation-cards {
        display: flex;
        .left {
        }
        .right {
          position: absolute;
          left: 555px;
          top: 250px;
        }
        .graphic-creation-card-spacing {
          margin-top: 15px;
        }
      }
    }

    .end-text {
      font-size: 32px;
      font-weight: 600;
      width: 900px;
      margin: 100px 0 25% 0;
    }
  }
}

@media screen and (max-device-width: 790px) {
  .agency {
    padding-top: 85px;
    z-index: 3;
    position: relative;
    .page-start {
      margin-bottom: 5px;
      padding: 10px;
      h2 {
        font-size: 100px;
        margin: 0;
        line-height: 70px;
        letter-spacing: 0px;
      }
      p {
        font-size: 14px;
        width: unset;
        margin: 0 10px 0 35px;
      }
      .subtitle {
        margin-bottom: unset;
      }
    }
    .blurry-section {
      max-width: unset;
      position: relative;
      margin: 0;
      padding: 40px 0 0 0;
      width: 99%;
      h4 {
        font-size: 55px;
        margin: 0 0 0 30px;
        line-height: 50px;
      }

      .engagements-section {
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        justify-content: center;
        width: unset;
        margin-top: 40px;
      }

      .graphic-creation {
        margin: 80px 0 0 0;
        width: 100%;
        h2 {
          width: 90%;
          font-size: 55px;
          margin-left: 30px;
          line-height: 50px;
        }
        p {
          margin-left: 30px;
          width: 90%;
        }
        .subtitle {
          margin-left: 30px;
        }

        .graphic-creation-cards {
          justify-content: center;
        }
      }

      .end-text {
        font-size: 20px;
        width: unset;
        margin: 60px 30px 40% 30px;
      }
    }
  }
}
</style>
