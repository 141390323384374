<template>
  <a
    class="large-horizontal-card"
    href="https://griffx.herokuapp.com/griffX/homepage"
    target="_blank"
  >
    <div class="container">
      <img
        src="../assets/griffix-image.png"
        alt="dino circuits"
        class="dino-image"
      />
      <img src="../assets/logo-griffix.png" alt="logo dino" class="dino-logo" />
      <div class="text-container">
        <h3>Retrouvez dino sur les circuits</h3>
        <p>
          Personnalisez votre kit-déco !<br />Amplifiez vos émotions et partagez
          la passion du sport mécanique.
        </p>
      </div>
    </div>
    <AnimatedArrow class="arrow-position" />
  </a>
</template>

<script>
import AnimatedArrow from './AnimatedArrow.vue';

export default {
  name: 'LargeHorizontalCard',
  components: { AnimatedArrow },
};
</script>

<style lang="scss" scoped>
.large-horizontal-card {
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  text-align: left;
  width: 850px;
  height: 250px;
  padding: 10px;
  display: flex;
  color: white;
  text-decoration: none;
  .container {
    border-radius: 10px;
    border: 1px solid #eb6c34;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .dino-image {
      width: 400px;
      border-radius: 10px;
      box-shadow: -6px 8px 15px 0px rgba(0, 0, 0, 0.37);
      position: absolute;
      top: -50px;
      left: -25px;
    }

    .dino-logo {
      position: absolute;
      width: 180px;
      left: -90px;
      top: -120px;
    }
    .text-container {
      width: 400px;
      margin: 20px 40px;
      h3 {
        margin: 0;
        font-size: 50px;
        font-family: 'Bebas Neue';
        line-height: 50px;
      }
      p {
        color: #eb6c34;
        font-weight: 600;
      }
    }
  }
  .arrow-position {
    position: absolute;
    right: 25px;
    bottom: 25px;
  }
}

@media screen and (max-device-width: 790px) {
  .large-horizontal-card {
    width: 330px;
    height: 170px;
    .container {
      .dino-image {
        width: 200px;
        top: -25px;
        left: -15px;
      }

      .dino-logo {
        position: absolute;
        width: 120px;
        top: -70px;
        left: -30px;
      }
      .text-container {
        margin: 20px 0px 20px 190px;
        h3 {
          font-size: 22px;
          line-height: 22px;
        }
        p {
          font-size: 11px;
        }
      }
    }
    .arrow-position {
      bottom: 20px;
    }
  }
}
</style>
