<template>
  <div class="team-card">
    <img alt="team member" :src="loadImg" />
    <p class="title">{{ subTitle }}</p>
    <p class="subtitle">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'TeamCard',
  props: {
    imgName: {
      required: true,
      type: String,
    },
    subTitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    loadImg() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../assets/team/${this.imgName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-card {
  max-width: 200px;
  img {
    width: 180px;
    height: 180px;
    border-radius: 100px;
  }

  .title {
    margin: 0 0 5px 0;
  }
  .subtitle {
    font-weight: 800;
    margin: 0;
  }
}

@media screen and (max-device-width: 790px) {
  .team-card {
    max-width: none;
    img {
      width: 100px;
      height: 100px;
    }

    .title {
      margin: 0 0 5px 0;
    }
    .subtitle {
      width: 140px;
      font-weight: 800;
      margin: 0;
    }
  }
}
</style>
