<template>
  <div class="custom-footer">
    <img src="../assets/footer-bg.png" alt="footer" class="footer-decoration" />
    <div class="left-content">
      <img src="../assets/logo-footer.png" alt="logo footer" />
      <div>
        <p><b>DINO Communication</b></p>
        <p>Av. des États-Unis<br />5 Rue Clément Ader<br />31140 Aucamville</p>
        <p>
          05 36 89 63 54<br />06 49 60 69 61<br />contact@dino-communication.fr
        </p>
      </div>
    </div>
    <div class="right-content">
      <div class="socials">
        <a
          href="https://www.instagram.com/dino.communication?igsh=bHAwdmR3MGtkbjI5"
          target="_blank"
        >
          <img src="../assets/socials/insta.png" alt="instagram" />
        </a>
        <a
          href="https://www.facebook.com/share/14wSik7HCt/?mibextid=LQQJ4d"
          target="_blank"
        >
          <img src="../assets/socials/facebook.png" alt="facebook" />
        </a>
        <a
          href="https://www.linkedin.com/company/dino-communication/"
          target="_blank"
        >
          <img src="../assets/socials/linkedin.png" alt="linkedin" />
        </a>
      </div>
      <p>© {{ currentYear }} DINO Communication</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomFooter',

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: black;
  .footer-decoration {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 5;
  }
  .left-content {
    z-index: 6;
    display: flex;
    text-align: left;
    margin: 0 0 70px 100px;
    img {
      width: 140px;
      height: 200px;
      margin-right: 30px;
    }
  }

  .right-content {
    z-index: 6;
    text-align: right;
    margin: 0 100px 70px 0;
    .socials {
      img {
        width: 50px;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-device-width: 790px) {
  .custom-footer {
    flex-direction: column;
    padding: 20px;
    .footer-decoration {
      left: 0;
      bottom: 280px;
    }
    .left-content {
      display: flex;
      margin: 0 0 20px 0;
      p {
        font-size: 14px;
      }
      img {
        width: 140px;
        height: 200px;
        margin-right: 10px;
      }
    }

    .right-content {
      display: flex;
      margin: 0;
      p {
        font-size: 14px;
      }
      .socials {
        display: flex;
        column-gap: 5px;
        img {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
