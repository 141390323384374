<template>
  <div class="labeled-image" :style="cssVars">
    <p v-if="isAfter" class="tag">APRÈS</p>
    <p v-else class="tag">AVANT</p>
  </div>
</template>

<script>
import imgg from '@/assets/atelier-pictures/vitrophanie-apres.png';

export default {
  name: 'LabeledImage',
  props: {
    imgPath: {
      required: true,
      type: String,
    },
    isAfter: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      imgg,
    };
  },

  computed: {
    loadImg() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../assets${this.imgPath}`);
    },
    cssVars() {
      return {
        '--background-image': `url(${this.loadImg})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.labeled-image {
  height: 480px;
  width: 480px;
  border-radius: 5px;
  background-image: var(--background-image);
  background-size: cover;
  position: relative;

  .tag {
    padding: 2px 15px 0px 15px;
    background-color: #eb6c34;
    font-family: 'Bebas Neue';
    font-size: 28px;
    position: absolute;
    border-radius: 5px;
    left: -10px;
  }
}

@media screen and (max-device-width: 790px) {
  .labeled-image {
    height: 160px;
    width: 160px;
    .tag {
      padding: 2px 5px 0px 5px;
      font-size: 18px;
      top: -10px;
    }
  }
}
</style>
