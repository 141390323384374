<template>
  <header class="header">
    <a class="logo-link" @click="goToHome" @keydown="goToHome">
      <img src="../assets/logo-header.png" alt="logo" class="logo" />
    </a>
    <div class="links-list">
      <router-link v-for="(item, i) in headerLinks" :to="item.link" :key="i">
        {{ item.name }}
      </router-link>
      <p @click="goToFooter" @keydown="goToFooter">CONTACT</p>
    </div>
    <Slide class="burger-menu" right>
      <router-link v-for="(item, i) in headerLinks" :to="item.link" :key="i">
        {{ item.name }}
      </router-link>
      <p @click="goToFooter" @keydown="goToFooter">CONTACT</p>
    </Slide>
  </header>
</template>

<script>
import { Slide } from 'vue3-burger-menu';

export default {
  name: 'CustomHeader',
  components: { Slide },

  data() {
    return {
      headerLinks: [
        {
          name: 'AGENCE',
          link: '/agence',
        },
        {
          name: 'ATELIER',
          link: '/atelier',
        },
      ],
    };
  },

  methods: {
    goToFooter() {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    },
    goToHome() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  font-family: 'Bebas Neue';
  width: 100%;
  position: fixed;
  z-index: 10;

  .burger-menu {
    display: none;
  }
  .logo-link {
    margin: 0;
    .logo {
      width: 50px;
      margin-left: 100px;
    }
  }

  .links-list {
    margin-right: 50px;
    display: flex;
  }

  a,
  p {
    color: white;
    font-size: 30px;
    text-decoration: none;
    margin: 0 50px;
    font-weight: 400;
    cursor: pointer;
  }
}

@media screen and (max-device-width: 790px) {
  .header {
    .logo-link {
      margin: 0;
      .logo {
        margin-left: 15px;
      }
    }

    .links-list {
      display: none;
    }

    .burger-menu {
      display: block;
      position: relative;
      z-index: 15;
      margin: -120px 80px 0 0;
    }

    a,
    p {
      color: white;
      font-size: 30px;
      text-decoration: none;
      margin: 0 50px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>

<style>
.bm-burger-bars {
  background-color: white !important;
}

.bm-menu {
  backdrop-filter: blur(10px) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.line-style {
  border-radius: 10px !important;
  height: 15% !important;
}
</style>
