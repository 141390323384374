<template>
  <div class="product-card">
    <div class="container">
      <img :src="imgPath" alt="advertised-product" />
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    title: {
      required: true,
      type: String,
    },
    imgName: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      imgPath: require(`../assets/products/${this.imgName}`),
    };
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  width: 320px;
  height: 320px;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  .container {
    border: 1px solid #eb6c34;
    border-radius: 10px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      z-index: 1;
    }
    h2 {
      font-family: 'Bebas Neue';
      font-size: 60px;
      margin: 0 0 15px 20px;
      z-index: 2;
      line-height: 50px;
    }
  }
}

@media screen and (max-device-width: 790px) {
  .product-card {
    width: 150px;
    height: 150px;
    padding: 8px;
    .container {
      h2 {
        font-size: 30px;
        margin: 0 0 5px 10px;
        line-height: 30px;
      }
    }
  }
}
</style>
