
import VerticalCard from '../components/VerticalCard.vue';
import TeamCard from '../components/TeamCard.vue';
import HorizontalCard from '../components/HorizontalCard.vue';
import LargeHorizontalCard from '../components/LargeHorizontalCard.vue';

export default {
  name: 'HomePage',
  components: {
    VerticalCard,
    TeamCard,
    HorizontalCard,
    LargeHorizontalCard,
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 790;
    },
  },

  data() {
    return {
      verticalCards: [
        {
          title: 'Studio graphique',
          subTitle: 'Exprimez vos idées en création graphique',
          textContent:
            'Rendez visible votre identité et démarquez vous des autres. Nous sommes à votre écoute.',
          imgPath: '/services/graphic-studio.png',
          toLink: '/agence?id=studio-graphique',
        },
        {
          title: "Habillage d'interieur",
          subTitle:
            'Le support idéal pour communiquer de manière originale et personalisée',
          textContent:
            "Transformez votre espace intérieur pour qu'il ne passe pas innaperçu.",
          imgPath: '/services/interior-decoration.png',
          toLink: '/atelier?id=habillage-interieur',
        },
        {
          title: 'Flocage et covering',
          subTitle: 'Véhiculer votre identité chaque jour dans l’espace urbain',
          textContent:
            'Votre véhicule est lui aussi un atout pour votre reconnaissance et la diffusion de votre message.',
          imgPath: '/services/flocage.png',
          toLink: '/atelier?id=flocage-covering',
        },
        {
          title: 'Supports publicitaires',
          subTitle:
            'Augmentez la visibilité et la notoriété de votre entreprise ou produit, tout en touchant un large public.',
          textContent:
            'Retrouvez un grand choix de produits pour une campagne publicitaire réussie.',
          imgPath: '/services/objets-publicitaire.png',
          toLink: '/atelier?id=produits-publicitaires',
        },
      ],
      horizontalCards: [
        {
          pictoName: 'hand-shake.png',
          text1:
            'Prendre du recul sur son activité est crucial. Nos analyses sur votre marché et positionnement définissent une proposition adéquate',
          text2:
            'Nous sommes là pour vous surprendre et vous proposer des solutions intelligentes et surtout dans votre budget.',
          number: '01',
          title: 'Accompagnement',
        },
        {
          pictoName: 'hand-shake2.png',
          text1:
            'Bâtissons une relation basée sur la transparence et la confiance. Nous répondons à vos questions financières et trouvons des solutions.',
          text2:
            'Avant tout notre but est de comprendre votre entreprise, votre projet et l’environnement dans lequel vous évoluez.',
          number: '02',
          title: 'Conseil',
        },
        {
          pictoName: 'target.png',
          text1:
            'Se doter d’un objectif ambitieu et tangible. Nous cronstruisons votre plan d’action grâce à nos moyens matériels et immatériels. S’appuyer sur vos facteurs clé de réussite c’est indispensable pour votre développement et votre succès.',
          text2:
            'Votre nouvelle orientation marketing doit communiquer ces éléments qui feront toujours votre identité.',
          number: '03',
          title: 'Stratégie',
        },
        {
          pictoName: 'box.png',
          text1:
            'Notre atelier de conception et de fabrication relève les défis construits ensemble. Notre méthodologie dans la gestion de projet nous permet de garantir la qualité et le respect des délais tout en rationalisant vos coûts. ',
          text2:
            'Nous vous garantissons un accompagnement dans le temps et une grande réactivité. Votre succès et aussi le notre.',
          number: '04',
          title: 'Fabrication',
        },
      ],
      teamCards: [
        {
          imgName: 'mathieu.png',
          subTitle: 'Mathieu FLORENT',
          title: 'Gérant / Chef de projet ',
        },
        {
          imgName: 'clemence.png',
          subTitle: 'Clémence SOHN',
          title: 'Graphisme / Atelier',
        },
      ],
    };
  },
};
